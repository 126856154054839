/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react'
import { Layout } from '../../layout'
import { socket } from '../../utils/socket';
import { useParams } from 'react-router-dom';
import { setToStorage } from '../../constant/storage';
import { STORAGE_KEYS } from '../../constant/storageKeys';
import { GOOGLE_API_KEY, GOOGLE_MAP_API } from '../../constant/url';
import { successToast } from '../../constant/toast';
import { DirectionsRenderer, DirectionsService, GoogleMap, Marker, MarkerF, Polyline, PolylineF } from '@react-google-maps/api';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
// import { GoogleMap, Marker, Polyline } from 'react-google-maps';

type Geometry = {
    lat: number;
    lng: number;
    completeAddress?: string;
    isCurrent?: boolean;
};
const customPickupIcon = {
    url: `http://maps.google.com/mapfiles/ms/icons/blue-dot.png`, // Blue marker icon for pickup
};

const customDriverIcon = {
    url: `http://maps.google.com/mapfiles/ms/icons/red-dot.png`, // Red marker icon for driver
};
const mapContainerStyle = {
    height: '400px',
    width: '600px',
};

const Tracking = () => {

    const [socketStatus, setSocketStatus] = useState<any>();
   
    const [driverLocation, setDriverLocation] = useState<any>({
        latitude: 0,
        longitude: 0
    });
    const [details, setDetails] = useState<any>();
    const [bookingType, setBookingType] = useState<number>(0);
    const { accessToken, bookingId } = useParams();
    const [isPickUpComplete, setIsPickUpComplete] = useState<boolean>(false);
    const [pickupLocation, setPickupLocation] = useState<any>({
        latitude: 0,
        longitude: 0
    })
    const [directionResponse, setDirectionsResponse] = useState<any>(null);
    const [routeCoordinates, setRouteCoordinates] = useState<Geometry[]>([]);
    const [initialRouteCoordinates, setInitialRouteCoordinates] = useState<Geometry[]>([])
    const [dropofLocation, setDropofLocation] = useState<any>({
        latitude: null,
        longitude: null
    })
    const [distance1, setDistance1] = useState('');
    const mapRef = useRef<any>(null);


    console.log(socketStatus, "socket status");
    console.log(driverLocation, "driverLocation");


    const title = (() => {

        switch (socketStatus) {
            case 2:
                return ('Chuffeur on way');
            case 3:
                return ('Chaffeur is near');
            case 4:
                return ('Chaffeur arriving');
            case 5:
                return ('Piclup complete');
            case 7:
                return ('Your ride is started');
            case 21:
                successToast('Ride is Completed');
                return ('Ride is Completed')
            case 10:
                return ('Your Valet is on the way');
            case 11:
                return ('Your Valet is near by location');
            case 12:
                return ('Your drop off completed');
            case 13:
                return ('Your Valet is on the way for pickup');
            case 14:
                return ('Your Valet is near by location');
            case 15:
                return ('Valet is picked the vehicle');
            case 16:
                return ('Pickup heading to garage');
            case 17:
                successToast('Reached garage');
                return ('Reached garage')
            default:
                return bookingType === 1 ? 'Your Valet is assigned' : 'Your Chauffeur is assigned';
        }
    })();


   

    // useEffect(() => {
    //     if (pickupLocation?.latitude && dropofLocation?.latitude) {
    //         setDirectionsReady(true);
    //     }
    // }, [pickupLocation]);

    useEffect(() => {
        if (accessToken) {
        setToStorage(STORAGE_KEYS.token, accessToken)
        }
    }, [])

    // useEffect(() => {
    //     if (pickupLocation || dropofLocation) {
    //         onCenter();
    //     }
    // }, [pickupLocation, dropofLocation]);


    const onMapLoad = useCallback((map: google.maps.Map) => {
        mapRef.current = map;
    }, []);

    async function calculateRoute(pickUpLocation: any, dropOffLocation: any) {
        if (dropOffLocation?.latitude === '' || pickUpLocation?.latitude === '') {
            return
        }
        // eslint-disable-next-line no-undef
        const directionsService = new google.maps.DirectionsService()
        const results = await directionsService.route({
            origin: { lat: pickUpLocation?.latitude, lng: pickUpLocation?.longitude },
            destination: { lat: dropOffLocation?.latitude, lng: dropOffLocation?.longitude },
            // eslint-disable-next-line no-undef
            travelMode: google.maps.TravelMode.DRIVING,
        })
        const points = decode(results.routes[0].overview_polyline);
        console.log(points, "points array");
        if (mapRef.current && points.length > 0) {
            const bounds = new google.maps.LatLngBounds();

            points.forEach((point: any) => {
                if (Array.isArray(point)) {
                    bounds.extend(new google.maps.LatLng(point[0], point[1]));
                } else {
                    bounds.extend(new google.maps.LatLng(point.lat, point.lng));
                }
            });
            console.log(bounds, "bounds")
            mapRef.current.fitBounds(bounds);
        }
        setRouteCoordinates(points);
        setInitialRouteCoordinates(points);
        // if (mapRef?.current) {
        //     mapRef?.current?.fitBounds(bounds);
        // }

        setDirectionsResponse(results)
        console.log(directionResponse, "array of routes");

        // setDistance(results.routes[0].legs[0].distance.text)
        // setDuration(results.routes[0].legs[0].duration.text)
    }


    const decode = (t: string, e = 5) => {
        let points = [];
        let index = 0,
            len = t.length;
        let lat = 0,
            lng = 0;

        while (index < len) {
            let shift = 0,
                result = 0;

            do {
                var b = t.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);

            lat += result & 1 ? ~(result >> 1) : result >> 1;
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            (shift = 0), (result = 0);

            do {
                var b = t.charCodeAt(index++) - 63;
                result |= (b & 0x1f) << shift;
                shift += 5;
            } while (b >= 0x20);

            lng += result & 1 ? ~(result >> 1) : result >> 1;
            points.push({ lat: lat / 1e5, lng: lng / 1e5 });
        }
        return points;
    };





    useEffect(() => {
        socket.emit('joinRoom', { bookingId: bookingId });
        socket?.on('joinRoomOk', (value: any) => {
            console.log(value, 'event is here');
        });

        socket?.on('orderStatusUpdated', (value: any) => {
            setSocketStatus(value?.data?.orderStatus);
            setBookingType(value?.data?.bookingUserType);
            setDetails(value?.data)
            setPickupLocation({
                latitude: value.data.pickup.latitude,
                longitude: value.data.pickup.longitude
            })
            // setDropofLocation({
            //     latitude: value.data.dropOf.latitude,
            //     longitude: value.data.dropOf.longitude
            // })
            console.log(value?.data, "order status changes res");

        });
        socket?.on('updateLocationOk', (value: any) => {
            try {
                console.log(value?.data, 'update location res');
                setDriverLocation({
                    latitude: value.data.latitude,
                    longitude: value.data.longitude
                });
            } catch (error) {
                console.error('Error processing updateLocationOk event:', error);
            }
        });
    }, [socket])

    const updatePolyline = (currentLocation: Geometry) => {
        const closestIndex = routeCoordinates.findIndex(coord => {
            //@ts-ignore
            return haversineDistance(coord, currentLocation) < 0.05; // 100 meters threshold
        });

        if (closestIndex !== -1) {
            const updatedRoute = routeCoordinates.slice(closestIndex);
            setRouteCoordinates(updatedRoute);
            //   setInitialRouteCoordinates(updatedRoute);
        }
    };

    const haversineDistance = (
        point1: { latitude: any; longitude: any },
        point2: { latitude: number; longitude: number },
    ) => {
        const R = 6371; // Earth's radius in kilometers
        const dLat = (point2.latitude - point1.latitude) * (Math.PI / 180);
        const dLon = (point2.longitude - point1.longitude) * (Math.PI / 180);
        const lat1 = point1.latitude * (Math.PI / 180);
        const lat2 = point2.latitude * (Math.PI / 180);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);

        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c;
    };

    const hasDeviatedFromRoute = (
        currentLocation: any,
        routeCoordinates: any,
    ) => {
        const thresholdDistance = 0.2; // Threshold in kilometers
        for (const point of routeCoordinates) {
            if (haversineDistance(currentLocation, point) < thresholdDistance) {
                return false;
            }
        }
        return true;
    };


    useEffect(() => {
        if (socketStatus === 15 || socketStatus === 7) {
            calculateRoute(pickupLocation, dropofLocation)
        }
    }, [socketStatus])

    useEffect(() => {
        if (routeCoordinates?.length > 1) {
            updatePolyline(pickupLocation);
            if (
                pickupLocation?.latitude &&
                pickupLocation?.longitude &&
                dropofLocation?.latitude &&
                dropofLocation?.longitude
            ) {
                const updatedDis = haversineDistance(dropofLocation, pickupLocation);
                const roundedDistance = parseFloat(updatedDis.toFixed(1));
                const formattedDistanceText =
                    roundedDistance % 1 === 0
                        ? `${Math.round(roundedDistance)} km`
                        : `${roundedDistance} km`;
                setDistance1(formattedDistanceText);
            }
        }
    }, [pickupLocation, dropofLocation]);

    useEffect(() => {
        calculateRoute(driverLocation, pickupLocation)
    }, [driverLocation, pickupLocation])
    console.log(mapRef, "direction")




    return (
        <Layout>
            <main className="content">
                <section className="tracking_sc">
                    <div className="lt_s">

                        <div>
                            <GoogleMap
                                mapContainerStyle={mapContainerStyle}
                                center={{ lat: pickupLocation?.latitude || 30.6983149, lng: pickupLocation?.longitude || 76.6560951 }}
                                zoom={8}
                                onLoad={onMapLoad}
                                ref={mapRef}

                                options={{
                                    gestureHandling: 'auto',
                                    fullscreenControl: true,
                                    zoomControl: true,
                                    mapTypeControl: true,
                                    streetViewControl: true,
                                    scaleControl: true,
                                    rotateControl: true,
                                }}
                            >
                                {socketStatus === 8 || socketStatus === 17 ? ("") : (
                                    <>
                                        <MarkerF
                                            position={{ lat: pickupLocation.latitude, lng: pickupLocation.longitude }}
                                            icon={customPickupIcon}
                                            title="Pickup Location"
                                        />
                                        <MarkerF
                                            position={{ lat: driverLocation.latitude, lng: driverLocation.longitude }} // Ensure lat/lng format is correct
                                            title="Driver Location"
                                            icon={customDriverIcon}
                                        />
                                     </>
                                )} 

                             
                                {
                                    (socketStatus === 8 || socketStatus === 17) && !routeCoordinates ? ("") : (
                                        <PolylineF
                                            path={routeCoordinates}
                                        />
                                    )
                                }
                            </GoogleMap>



                        </div>
                    </div>
                    <div className="rt_s">
                        <h1>Tracking Detail</h1>
                        <ul className="track_detail">
                            <li>
                                <span>{bookingType === 1 ? "Valet" : "Chauffeur"} Details:</span>
                                <figure className="image_flex">
                                    <img src={`/assets/images/user_placeholder.png`} alt="Image" />
                                    <figcaption>
                                        <strong>{details?.driverId?.firstName + " "+details?.driverId?.lastName ||"-"}</strong>
                                        <span>{details?.driverId?.dialCode+"-" + details?.driverId?.phoneNo ||"-"}</span>
                                    </figcaption>
                                </figure>
                                <a className="call_to" href='tel:+91-9876543210'><img src={`/assets/images/phone_icon.svg`} alt="Image" /></a>
                            </li>
                            <li>
                                <span>Pickup:</span>
                                <strong>{details?.pickup?.address || "-"}</strong>
                            </li>
                            <li>
                                <span>Drop Off:</span>
                                <strong>{details?.dropOf?.address || "_"}</strong>
                            </li>

                        </ul>
                        <ul className="track_status">
                            <li className="active">
                                <strong>{title || ""}</strong>
                                {/* <strong className='eta'>ETA: 15 min</strong> */}
                                {/* <span> Sep 2, 2024, 11:36:31 AM </span> */}
                            </li>
                        </ul>
                    </div>
                </section>
            </main>
        </Layout>
    )
}

export default Tracking