import { Routes, Route } from 'react-router-dom';
import Pages from './pages';

const Routing = () => {
  return (
    <Routes>
      <Route path='/' element={<Pages.Tracking />} />
    </Routes>
  )
}

export default Routing